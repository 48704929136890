const translations = require('../../assets/configs/babel/en.json')
const _ = require('lodash')

const tmpBabel = require('../tmpBabel.json')

// sometimes webpacker in production doesn't rebuild babel
const forceRebuildToken = require('../forceBabelRebuild')

window.__localization = {
  locale: 'en',
  translations: _.merge({}, {en: tmpBabel["en"]}, translations)
}
